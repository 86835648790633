import React, { useState, useEffect } from "react";
import "./App.css";
import VisitorService from "./service/visitor-service";

function App() {
  const [visitors, setVisitors] = useState("");

  useEffect(() => {
    const visitorService = new VisitorService();
    visitorService
      .saveInfo()
      .then((res) => {
        //console.log("Data saved successfully..");
      })
      .catch((err) => {
        //console.log("Problem saving data...");
      });

    visitorService
      .fetchVisitorCount()
      .then((res) => {
        setVisitors(res.count);
      })
      .catch((error) => {
        console.log("Problem getting user count..");
      });
  }, []);

  return (
    <React.Fragment>
      <ApplyStyle counter={visitors} />
    </React.Fragment>
  );
}

export default App;

const ApplyStyle = ({ counter }) => {
  if (!counter) {
    return null;
  }
  let str = String(counter).padStart(5, "0").split("");
  let result =
    str &&
    str.map((digit, index) => {
      return (
        <div
          key={index}
          style={{
            width: "25px",
            lineHeight: "30px",
            border: "1px solid #ddd",
            color: "gold",
            display: "inline-block",
            fontSize: "20px",
            backgroundColor: "ActiveBorder",
            textAlign: "center",
          }}
        >
          {digit}
        </div>
      );
    });

  return <>{result}</>;
};
