import axios from "axios";

class VisitorService {
  constructor() {
    this.server =
      "https://backend-svc-brajalal-com.onrender.com" || process.env.REACT_APP_BackendServiceURL || "http://localhost:5000";
    this.Api_Key = "f602989a8a9f455ab0670c5d8937c390";
  }

  saveInfo = async () => {
    // const ipInfo = await axios.get("https://api.ipify.org/?format=json");
    // const userInfo = await axios.get(`http://ip-api.com/json/${ipInfo.data.ip}`);
    const userData = await axios.get(`https://ipgeolocation.abstractapi.com/v1/?api_key=${this.Api_Key}`);

    const options = {
      url: `${this.server}/save`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      mode: "cors",
      data: userData.data,
    };
    return await axios(options);
  };

  fetchVisitorCount = async () => {
    const res = await fetch(`${this.server}/info`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      mode: "cors",
    });
    return await res.json();
  };
}

export default VisitorService;
